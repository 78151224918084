
.BaseSelect { // vars
	--height : 2em;
}

.BaseSelect { // debug styling
}

.BaseSelect { // layout + styling
	&__selectedOptionLabel {
		//background-color: fade( red, 20 );
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
	}
	&__select {
		opacity: 0;
		cursor: pointer;
	}
}

.BaseSelect { // hover
}
.BaseSelect { // focus
}
.BaseSelect { // disabled
}
