
//.FormField__inputRadio { opacity: 1 !important; }

.BaseRadio { // css vars
	--height : 2em;
	--width  : 2em;
	--padding: 0.4em;
}

.BaseRadio {	// layout
	display: inline-block;
	flex-shrink: 0;

	&__inner {
		display: flex;
		padding: var(--padding);
	}
	&__fakeRadio {
		position: relative;
		pointer-events: none;
		flex-grow: 1;
	}
}
.BaseRadio { // styling
	&__inputRadio + &__fakeRadio circle {
		transition: var(--transition);

		&:first-child {
			stroke: currentColor;
			stroke-width: 1.25px;
		}
		&:last-child {
			r: 5.25px;
			fill: white;
		}
	}
	&__inputRadio:checked + &__fakeRadio circle {
		&:first-child {}
		&:last-child {
			r: 2px;
		}
	}
}

.BaseRadio { // hover
}
.BaseRadio { // focus
}
.BaseRadio { // disabled
}

/*
.BaseRadio {	// css vars
	--height                : 2em;
	--paddingLeft           : 0.25em;
	--paddingRight          : 0.25em;
	--labelPadding          : 0.25em;
	--border                : 1px solid red;
	--hoverBorder           : 1px solid blue;
	--focusBorder           : 1px solid green;
	--activeBackgroundColor : fade(black, 50);
	--activeForegroundColor : fade(black, 75);
}
.BaseRadio {
	//outline: 1px solid fade( red, 50);

	display: inline-flex;
	align-items: flex-start;
	cursor: pointer;

	&__inner {
		//background-color: fade( red, 15 );

		position: relative;
		height: var(--height);
		display: flex;
		align-items: center;
		padding-left: var(--paddingLeft);
		padding-right: var(--paddingRight);
	}
	&__input {
		position: absolute;
		top: 0; left: 0;
		right: 0; bottom: 0;
		height: 100%; width: 100%; // needed correct dimensions for validation tooltip placement
		opacity: 0;
	}
	&__checkbox {
		//.checkboxMixin();
	}
	&__label {
		min-height: var(--height);
		padding: var(--labelPadding);

		display: inline-block;
		display: flex;
		align-items: center;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
*/
