
	.BaseCheckbox { // css vars
		--height : 2em;
		--width  : 2em;
		--padding: 0.4em;
	}

	.BaseCheckbox {	// layout
		display: inline-block;
		flex-shrink: 0;

		&__inner {
			display: flex;
			padding: var(--padding);
		}
		&__fakeCheckbox {
			position: relative;
			pointer-events: none;
			flex-grow: 1;

			svg {
				position: absolute;
				top: 1; left: 1; right: 1; bottom: 1;
				opacity: 0;
			}

			&--isSelected svg { opacity: 1; }
		}
	}
	.BaseCheckbox { // styling
		&__fakeCheckbox {
			background-color: fade( red, 15 );
			background-color: white;
			border-radius: var(--borderRadius);
			cursor: pointer;
		}
	}

	/*
	.BaseCheckbox { // draw path animation
		svg path {
			stroke-dasharray: 20;
    		stroke-dashoffset: 20;
			//transition: all 0.25s ease-in;
		}
		&__fakeCheckbox--isSelected svg path {
			//stroke-dasharray: 20;
			//stroke-dashoffset: 0;
			//transition: all 0.35s ease-out;
			animation: dash 0.5s linear normal 1;
			animation-fill-mode: forwards;
			transform-origin: center;
		}
		@keyframes dash {
			0% {
				stroke-dashoffset: 20;
			}
			50% {
				transform: scale(1);
			}
			60% {
				stroke-dashoffset: 0;
			}
			65% {
				//stroke-dashoffset: 0;
			}
			80%{
				transform: scale(1.5);
			}
			90%{
				//transform: scale(0.75);
			}
			100% {
				stroke-dashoffset: 0;
				transform: scale(1);
			}
		}
	}
	*/

	.BaseCheckbox { // hover
	}
	.BaseCheckbox { // focus
	}
	.BaseCheckbox { // disabled
	}

