
.BaseText { // css vars
	--height : 2em;
}

.BaseText {	// layout
}
.BaseText { // styling
}

.BaseText { // hover
}
.BaseText { // focus
}
.BaseText { // disabled
}
