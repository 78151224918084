
.BaseTextarea { // css vars
	--height : 5em;
}

.BaseTextarea { // hover
}
.BaseTextarea { // focus
}
.BaseTextarea { // disabled
}
