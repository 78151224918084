/**
 *	2022-01-31	feature: added &__clearBtn for BaseSelect
 *
 */
.FormField {
  --height: 2em;
  --width: 100%;
  --padding: 0.5em;
  --placeholderOpacity: 0.4;
  --fontFamily: inherit;
  --fontWeight: inherit;
  --transition: all 0.15s ease;
  --color: currentColor;
  --colorOpacity: 1;
  --colorOpacity-hover: 1;
  --colorOpacity-focus: 1;
  --colorOpacity-disabled: 0.5;
  --bgColor: currentColor;
  --bgOpacity: 0.025;
  --bgOpacity-hover: 0.05;
  --bgOpacity-focus: 0.075;
  --bgOpacity-disabled: 0;
  --borderRadius: 0.25em;
  --borderWidth: 1px;
  --borderStyle: solid;
  --borderColor: currentColor;
  --borderOpacity: 0.5;
  --borderOpacity-hover: 0.5;
  --borderOpacity-focus: 1;
  --borderOpacity-disabled: 0.25;
}
.FormField pre {
  font-size: 12px;
}
.FormField {
  position: relative;
  width: var(--width);
}
.FormField__inner {
  position: relative;
  width: 100%;
  display: flex;
}
.FormField__inner:not(.FormField--BaseTextarea .FormField__inner) {
  height: var(--height);
}
.FormField--BaseTextarea .FormField__inner {
  min-height: var(--height);
}
.FormField__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.FormField__border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.FormField__selectedOptionLabel {
  display: flex;
  flex-grow: 1;
  align-items: center;
  overflow: hidden;
}
.FormField__select,
.FormField__input,
.FormField__textarea {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: inherit;
  max-width: 100%;
  flex-grow: 1;
  appearance: none;
}
.FormField__textarea {
  min-height: var(--height) !important;
}
.FormField__inputCheckbox,
.FormField__inputRadio {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.FormField__inputCheckbox svg,
.FormField__inputRadio svg {
  position: absolute;
  top: 1;
  left: 1;
  right: 1;
  bottom: 1;
}
.FormField__clearBtn,
.FormField__openIcon {
  top: 50%;
  height: calc(var(--height) * 0.5);
  width: calc(var(--height) * 0.5);
  transform: translateY(-50%);
}
.FormField__clearBtn {
  position: relative;
  margin: 0 calc(var(--height) * 0.25);
}
.FormField__openIcon {
  position: relative;
  margin: 0 calc(var(--height) * 0.25);
  pointer-events: none;
}
.FormField__clearBtn + .FormField__openIcon {
  margin-left: 0em;
}
.FormField__inner {
  transition: var(--transition);
  color: inherit;
}
.FormField__bg {
  transition: var(--transition);
  background-color: var(--bgColor);
  border-radius: var(--borderRadius);
  opacity: var(--bgOpacity);
}
.FormField__border {
  transition: var(--transition);
  border-radius: var(--borderRadius);
  border-width: var(--borderWidth);
  border-style: var(--borderStyle);
  border-color: var(--borderColor);
  opacity: var(--borderOpacity);
}
.FormField__selectedOptionLabel {
  padding: 0 var(--padding);
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.FormField__input,
.FormField__textarea {
  border: none;
  background-color: transparent;
  outline: none;
  font-family: var(--fontFamily);
  font-weight: var(--fontWeight);
  color: var(--color);
  opacity: var(--colorOpacity);
}
.FormField__input {
  transition: var(--transition);
  text-indent: var(--padding);
}
.FormField__textarea {
  padding: var(--padding);
}
.FormField__selectedOptionLabel {
  transition: var(--transition);
  cursor: pointer;
}
.FormField__selectedOptionLabel--isUnset {
  opacity: var(--placeholderOpacity);
}
.FormField__input::placeholder,
.FormField__textarea::placeholder {
  transition: var(--transition);
  opacity: var(--placeholderOpacity);
  color: currentColor;
}
.FormField__inputCheckbox,
.FormField__inputRadio {
  opacity: 0;
  cursor: pointer;
}
.FormField--resizeable-both .FormField__textarea {
  resize: both;
}
.FormField--resizeable-horizontal .FormField__textarea {
  resize: horizontal;
}
.FormField--resizeable-vertical .FormField__textarea {
  resize: vertical;
}
.FormField--resizeable-none .FormField__textarea {
  resize: none;
}
.FormField__clearBtn {
  transition: var(--transition);
  cursor: pointer;
  opacity: 0.25;
}
.FormField__clearBtn path {
  fill: currentColor;
}
.FormField__openIcon {
  transition: var(--transition);
  opacity: 0.5;
}
.FormField__openIcon path {
  fill: currentColor;
}
.FormField:hover .FormField__bg {
  opacity: var(--bgOpacity-hover);
}
.FormField:hover .FormField__border {
  opacity: var(--borderOpacity-hover);
}
.FormField:hover .FormField__input,
.FormField:hover .FormField__textarea {
  opacity: var(--colorOpacity-hover);
}
.FormField:hover .FormField__clearBtn {
  opacity: 0.5;
}
.FormField:hover .FormField__clearBtn:hover {
  opacity: 1;
}
.FormField:hover .FormField__openIcon {
  opacity: 1;
}
.FormField:focus-within .FormField__bg {
  opacity: var(--bgOpacity-focus);
}
.FormField:focus-within .FormField__border {
  opacity: var(--borderOpacity-focus);
}
.FormField:focus-within .FormField__input,
.FormField:focus-within .FormField__textarea {
  opacity: var(--colorOpacity-focus);
}
.FormField--isDisabled {
  pointer-events: none;
}
.FormField--isDisabled .FormField__bg {
  opacity: var(--bgOpacity-disabled);
}
.FormField--isDisabled .FormField__border {
  opacity: var(--borderOpacity-disabled);
  border-style: dashed;
}
.FormField--isDisabled .FormField__input,
.FormField--isDisabled .FormField__textarea,
.FormField--isDisabled .FormField__selectedOptionLabel,
.FormField--isDisabled .FormField__select {
  opacity: var(--colorOpacity-disabled);
}
.FormField--isDisabled .FormField__clearBtn {
  display: none;
}
.FormField--isDisabled .FormField__openIcon {
  opacity: 0.25;
}
.FormField--isDisabled .FormField__fakeCheckbox svg {
  opacity: 0.35;
}
.FormField--isDisabled .FormField__fakeRadio svg {
  opacity: 0.25;
}
