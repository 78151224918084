
	.BaseSearchSelect { // vars
		--height : 2em;
	}

	.BaseSearchSelect {  // debug styling

	}

	.BaseSearchSelect { // layout + styling
		&__btnWrapper {
			//background-color: fade( red, 20 );

			position: absolute;
			top: 0; right: 0;
			height: var(--height);
			pointer-events: none;
			display: flex;
    		align-content: center;
    		flex-direction: row;
		}
		&__clearBtn {
			pointer-events: initial;
		}

		&__dropdownWrapper {
			position: relative;
			margin-top: 3px;
		}

		&__searchWrapper {
			position: relative;

			&Border {
				position: absolute;
				bottom: 0; left: 0; right: 0;
				border-bottom: 1px solid var(--borderColor);
				opacity: var(--borderOpacity);
			}
			&Input {
				padding: 0 var(--padding);
				height: var(--height);
				width: 100%;
				border: none;
				outline: none;
				color: inherit;
				font-family: inherit;
				font-size: inherit;
				font-weight: inherit;

				&::placeholder { color: inherit; opacity: 0.5; }
			}
			&:focus-within &Border { opacity: 1; }
			&Counter {
				//background-color: fade( red, 20 );
				position: absolute;
				top: 0; bottom: 0; right: 0;
				display: flex;
				align-items: center;
				padding: 0 var(--padding);
				opacity: 0.75;
			}
		}

		&__optionsWrapper {
			//background-color: fade( green, 20 );
			position: relative;
			max-height: calc( var(--height) * 6 );
			min-height: calc( var(--height) * 2 );
			overflow-x: hidden;
			overflow-y: auto;
			scroll-behavior: smooth;

			&Option {
				transition: all 0.1s ease;
				position: relative;
				display: flex;
				align-items: center;
				height: var(--height);
				padding: 0 var(--padding);
				cursor: pointer;
				user-select: none;

				&Border {
					transition: inherit;
					position: absolute;
					bottom: 0; left: 0; right: 0;
					border-bottom: 1px solid var(--borderColor);
					opacity: 0.25;
				}
				&Bg {
					transition: inherit;
					position: absolute;
					top: 0; bottom: 0; left: 0; right: 0;
					background-color: currentColor;
					pointer-events: none;
					opacity: 0;
				}
				&Label {
					transition: inherit;
					position: relative;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				&:hover:not(&--selected) &Bg { opacity: 0.2; }
				&--preSelected:not(&--selected) &Bg { opacity: 0.2; }
				&--selected &Bg { background-color: currentColor; opacity: 0.85; }
				&--selected &Label { color: white; }
			}
			&Option--noResultsMsg {
				opacity: 0.35;
			}
			&Option--noResultsMsg &OptionIcon {
				--stroke-width: 1;
				margin-right: 0.25em;
			}
		}
	}

	.BaseSearchSelect { // hover
	}
	.BaseSearchSelect { // focus
	}
	.BaseSearchSelect { // disabled
	}
